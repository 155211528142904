.ekosiet-copy-to-clipboard {

  padding: 0 .2rem;

  i {
    color: var(--kt-gray-500);
  }

  &.ekosiet-copy-to-clipboard--enabled:hover {
    cursor: pointer;

    i {
      color: var(--kt-gray-900);
    }
  }

}