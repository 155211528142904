//@import "react-medium-image-zoom/dist/styles.css";


#root-modals {

  @for $i from 0 through 6 {

    $zIndexBackdrop: #{1000 + (5 * $i)};
    $zIndexContent: #{1000 + (5 * $i) + 5};

    .modal-backdrop.show:nth-of-type(#{$i}) {
      z-index: $zIndexBackdrop;
    }
    div[role="dialog"][aria-modal="true"]:nth-of-type(#{$i}) {
      z-index: $zIndexContent;
    }
  }
}

.image-container {
  position: relative;
  display: inline-block;
  max-width: 150px;
  max-height: 150px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20px;
  }

  &:hover .overlay {
    opacity: 1;
  }
}

.tableFixHead {
  overflow: auto;
  max-height: 100vh;

  thead th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: #fff;
  }
}

.tableFixFirstColumn {
  //overflow: auto;
  //max-height: 100vh;
  th:first-child, td:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    background: #fff;
  }
}


/*

*/
.modal-dialog.modal-dialog-scrollable > .modal-content > form {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: hidden;
}

.dropdown-menu.dropdown-menu-lg {
  --bs-dropdown-item-padding-y: .8rem;
}

.address-container {
  display: grid;
}

.address-content, .overlay {
  grid-area: 1 / 1;
  align-items: center;
  justify-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.fit-content {
  width: 1%;
  white-space: nowrap;
}


.fixed-bottom-bar {
  position: fixed;
  z-index: 1000;
  left: calc(var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) + var(--kt-app-sidebar-gap-end, 0px));
  bottom: 0;

  // Note on px 30px: This is metronics $app-container-padding-x times 2 (left and right).
  padding: 10px 30px;

  height: 64px;
  width: calc(100% - var(--kt-app-sidebar-width) + var(--kt-app-sidebar-gap-start, 0px) + var(--kt-app-sidebar-gap-end, 0px));
  background: #fff;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}

.extra-products input[type=number]::-webkit-inner-spin-button {
  opacity: 0
}

.calendar-header-row {
  padding: 0;
  margin: 0 0 0 5px;
}

.calendar-row {
  border-style: solid;
  border-color: black;
  border-width: 1px 0 0 1px;
  padding: 0;
  margin: 0 0 0 5px;
}

.calendar-begin-or-end-row {
  border-style: solid;
  border-color: black;
  border-width: 0 0 0 1px;
  padding: 0;
  margin: 0 0 0 5px;
}

.calendar-cell {
  border-color: black;
  border-style: solid;
  border-width: 0 1px 1px 0;
}


.ekosiet-svg-preview {

  path,
  line,
  circle,
  symbol,
  use {
    fill: none;
  }

  .highlight {
    fill: none;
    stroke: fuchsia;
  }

  .neut-contour {
    fill: none;
    stroke: #111;
  }

  .start-point {
    fill: #00B2FF;
    stroke: none;
  }

  text {
    font-family: Arial, Tahoma, Sans-serif;
  }

  circle.debug {
    fill: red;
  }

  .axis {
    fill: none;
    stroke-width: .2;
    stroke: #ccc;
  }

  .bounding-box {
    fill: none;
    stroke-width: .2;
    stroke: greenyellow;
  }

  .sill {
    stroke: #666;
    stroke-width: .8;

    .line {
      stroke-width: .2;
      stroke: #ddd;
    }

  }


  .neut {

    .profile-left {
      stroke: none;
      //stroke: blue;
    }

    .profile-right {
      stroke: none;
      //stroke: blue;
    }

    .measure-line,
    .measure-line-start,
    .measure-line-end,
    .measure-line-text {
      display: block;
      stroke-width: .4;
    }

    .measure-line-text {
      stroke-width: .1;
      stroke: none;
      fill: red;
    }

    &.hover:hover {
      cursor: pointer;
      //filter: drop-shadow(0 0 2px #ccc);
      stroke: #111;

      .background {
        fill: #dddddd;
      }
    }
  }

  .neut,
  .neut-part {

    fill: #eee;
    stroke: #111;
    stroke-width: .8;

    .background {
      //stroke: none;
      fill: #eee;
    }

    .contour-wm50 {
      stroke: #555;
      stroke-width: .8;
      stroke-dasharray: 4 1;
      fill: #f8d7da;
    }

    .drill-hole {
      fill: #fefefe;
    }
  }


  .compartment {
    fill: #eee;
    //stroke: #111;
    stroke: none;
    //stroke: fuchsia;

    .background {
      //stroke: red;
      fill: transparent;
      //opacity: 0;
    }

    &:hover {
      //cursor: pointer;
      .background {
      }
    }

    .drainage-hole,
    .aeration-hole {
      //fill: #eee;
      stroke: #222;
      stroke-dasharray: 1;
      stroke-width: .3;
    }
  }

  .sluitpot-contour {
    fill: #f2f2f2;
    stroke: #777;

    &:hover {
      //cursor: pointer;
      //filter: drop-shadow(0 0 2px #ccc);
      //fill: #dddddd;
    }
  }


  .glazing-bar {
    fill: #eee;
    stroke: #222;
    stroke-dasharray: 4;
    stroke-width: .3;
  }

  .corner-piece {
    fill: #eee;
    stroke: #222;
    stroke-dasharray: 4;
    stroke-width: .3;
  }


  .glazing-profile,
  .stop-profile {
    fill: #eee;
    stroke: #222;
    stroke-width: .3;
  }

  .glazing-profile-stop,
  .stop-profile-stop {
    fill: none;
    stroke: #222;
    stroke-width: .3;
  }

  .glazing-profile-drill-hole,
  .stop-profile-drill-hole {
    stroke: #222;
    stroke-width: .3;
    fill: #fff;
  }

  .l-profile {
    fill: #eeeeee;
    stroke: #222;
    stroke-width: .3;
  }

  .hs-sliding-rail {
    //fill: #f2f2f2;
    fill: #e5e5e5;
    stroke: #888;
    //stroke-dasharray: 2;
    stroke-width: .3;
  }

  .hs-sliding-rail-center {
    stroke: none;
    fill: #d0d0d0;
  }

  .hs-sliding-rail-elevation {
    stroke: #d0d0d0;
    fill: #d0d0d0;;
    //stroke-width: .5;
  }

  .hs-bar {
    //fill: #f2f2f2;
    fill: #f5f5f5;
    stroke: #aaa;
    //stroke-dasharray: 2;
    stroke-width: .3;
  }

  .hs-bar-hole {
    stroke: #aaa;
    stroke-width: .3;
    fill: #fff;
  }

  .measure-line {
    stroke: #00a5dd;
    stroke-width: 1;
  }

  .measure-line-start,
  .measure-line-end {
    fill: #00a5dd;
    stroke: none;
  }

  .measure-line-text {
    dominant-baseline: middle;
    font-size: 65%;
    text-anchor: middle;
    fill: red;
    background: orange;
  }
}


.preview-svg {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & > svg {
    height: 100%;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
  }
}

.mirror {
  transform: scale(-1, 1);
}

.sluitpotbeslag-preview-container {
  svg {
    height: 50px;
    width: 50px;
  }
}

.configurator-modal {
  --bs-modal-width: calc(100vw - 50px);
  height: calc(100vh - 50px);

  .modal-content {
    height: 100%;
    overflow-y: scroll;
  }
}

.barcode {
  font-family: 'Libre Barcode 39 Text', sans-serif;
  font-size: 30px;
}

.neut-preview-container {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    height: 100%;
    width: 100%;
    min-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}

.sill-configuration-preview-container {
  height: 400px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  svg {
    height: 90%;
    //width: 100%;
    min-height: 100%;
    //max-width: 100%;
    object-fit: contain;
  }
}

.styled-scrollbars::-webkit-scrollbar {
  width: 15px; /* Mostly for vertical scrollbars */
  height: 25px; /* Mostly for horizontal scrollbars */
}

.tooltip {
  & > .tooltip-inner {
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    text-align: left;
  }
}

.btn-badge {
  font-size: 9px;
}


table.table-fit {
  width: auto !important;
  table-layout: auto !important;
}

table.table-fit thead th,
table.table-fit tbody td,
table.table-fit tfoot th,
table.table-fit tfoot td {
  width: auto !important;
}

.groeneveld-orderlines {
  .collapsing {
    transition: none !important;
  }
}

.wide-modal-62 {
  min-width: 62%;
}

.input-group > .btn {
  &:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 1px solid var(--kt-input-border-color);
    &:focus {
      border-color: var(--kt-input-focus-border-color);
    }
    &:hover {
     //background: var(--kt-);
    }
  }
}
.icon-badge{
  position: relative;
  margin-left: 60%;
  margin-top: -60%;
}