.tooltip.price-specification-tooltip {
  & > .tooltip-inner {
    width: 100%;
    min-width: 300px;
    max-width: 500px;
    text-align: left;
  }
  .price-specification-table {
    @for $level from 0 through 5 {
      tr.level-#{$level} {
        td {
          padding-left: $level *  .75rem;
        }
      }
    }
  }
}


