.timeline {
  position: relative;
width: 100%;
  max-height: 350px;
  overflow-y: auto;
  margin: 0 auto;

  .timeline-item {
    padding: 10px 40px;
    margin-left:7px;
    border-left: 3px solid #0a6aa1;
    position: relative;
    background-color: inherit;
    left: 0;
    &::after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      background-color: var(--bs-light);
      left: -9px;
      top: 10px;
      border-radius: 50%;
      z-index: 1;
    }
    .timeline-content {
      background-color: var(--bs-light);
      position: relative;
      border-radius: var(--bs-border-radius);
    }
  }
  .timeline-header {
    color: var(--bs-grey);
    font-size: 0.8em;
    margin-bottom: 0.5em;
  }
}
.item-enter {
   opacity: 0;
   transform: scale(0.7);
 }

.item-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 3000ms, transform 3000ms;
}